import React, { createContext, useState, useContext, useEffect } from 'react';

const BackgroundContext = createContext();

export const BackgroundProvider = ({ children }) => {
  const [backgroundClass, setBackgroundClass] = useState('');

  useEffect(() => {
    if (backgroundClass) {
      document.documentElement.classList.add(backgroundClass);
    }

    return () => {
      if (backgroundClass) {
        document.documentElement.classList.remove(backgroundClass);
      }
    };
  }, [backgroundClass]);

  return (
    <BackgroundContext.Provider value={{ setBackgroundClass }}>
      {children}
    </BackgroundContext.Provider>
  );
};

export const useBackground = () => useContext(BackgroundContext);