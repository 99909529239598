import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    changeApplication,
    getApplicationFromRoute,
    getApplicationId,
} from "../../slices/applicationSlice";
import { onRegister, onRegisterClean, registrationFailed } from "../../slices/registrationSlice";


import ImperialHeader from '../forms/partials/ImperialHeader';
import PigglywigglyHeader from '../forms/partials/PigglywigglyHeader';
import ImperialTextField from '../custom/controls/ImperialTextField';
import ImperialPasswordField from '../custom/controls/ImperialPasswordField';

import { useBackground } from "../custom/controls/BackgroundProvider";

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/imperial-typography-style";
import pigglywigglyStyle from "../../assets/jss/pigglywiggly-style";
import buttonStyles from "../../assets/jss/imperial-button-style";
import { Typography } from '@material-ui/core';
import validator from 'validator';
import classNames from "classnames";
const useStyles = makeStyles(styles);

const RegistrationForm = props => {
    const classes = useStyles();
    const buttonClasses = makeStyles(buttonStyles)();
    const pigglywigglyClasses = makeStyles(pigglywigglyStyle)();
    const dispatch = useDispatch();
    const { setBackgroundClass } = useBackground();
    
    const [username, setUsername] = useState("");
    const [currentpassword, setCurrentPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [confnewpassword, setConfNewPassword] = useState("");
    const [emailaddress, setEmailAddress] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");

    const [validUsername, setValidUsername] = useState(true);
    const [changedUsername, setChangedUsername] = useState(false);
    const [validCurrentPassword, setValidCurrentPassword] = useState(true);
    const [changedCurrentPassword, setChangedCurrentPassword] = useState(false);
    const [validNewPassword, setValidNewPassword] = useState(true);
    const [changedNewPassword, setChangedNewPassword] = useState(false);
    const [validConfNewPassword, setValidConfNewPassword] = useState(true);
    const [changedConfNewPassword, setChangedConfNewPassword] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState(true);
    const [changedEmailAddress, setChangedEmailAddress] = useState(false);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [changedPhoneNumber, setChangedPhoneNumber] = useState(false);

    const { isFormSubmitting, registrationError, errorMessage } = props;

    function validateNewPassword() {
        const isNewPassEmpty = validator.isEmpty(newpassword, { ignore_whitespace: true });
        const isConfNewPassEmpty = validator.isEmpty(confnewpassword, { ignore_whitespace: true });
        if (!isNewPassEmpty && !isConfNewPassEmpty && (newpassword === confnewpassword)) {
            return true;
        }
        return false;
    }

    function validateEmail() {
        const endsWith = "@imperialdade.com"; // must ends with
        const isEmail = validator.isEmail(emailaddress, { ignore_whitespace: false });
        const isValid = new RegExp(endsWith + "$").test(emailaddress);
        return isEmail && isValid;
    }

    function validatePhonenumber() {
        return phonenumber.length === 10 && !validator.isEmpty(phonenumber, { ignore_whitespace: true })
    }

    function validateFields(isSubmit) {

        if (errorMessage && errorMessage.length > 0) {
            setTimeout(() => {
                dispatch(onRegisterClean());
            }, "5000")
        }

        if (changedUsername || isSubmit) {
            setValidUsername(!!username ? !validator.isEmpty(username, { ignore_whitespace: true }) : false);
        }
        if (changedCurrentPassword || isSubmit) {
            setValidCurrentPassword(!!currentpassword ? !validator.isEmpty(currentpassword, { ignore_whitespace: true }) : false);
        }
        if (changedNewPassword || isSubmit) {
            setValidNewPassword(!!newpassword);
        }
        if (changedConfNewPassword || isSubmit) {
            setValidConfNewPassword(!!confnewpassword ? validateNewPassword() : false);
        }
        if (changedEmailAddress || isSubmit) {
            setValidEmailAddress(!!emailaddress ? validateEmail() : false);
        }
        if (changedPhoneNumber || isSubmit) {
            setValidPhoneNumber(!!phonenumber ? validatePhonenumber() : false);
        }
    }

    // Registration Page is only for Sales users
    const applicationIdFromRoute = getApplicationFromRoute(props);
    const applicationIdFromState = useSelector(getApplicationId);
    const defaultApplicationId = "sales-portal";
    useEffect(() => {
        // check if application Id in URL is default/'sales-portal'  
        if (applicationIdFromRoute !== defaultApplicationId) {
            dispatch(registrationFailed({ error: { message: " Application unknown" } }));
            console.log("[RegistrationdForm]: Application unknown");
        }
        else {
            // if application Id is different from state, then update state
            if (applicationIdFromRoute !== applicationIdFromState)
                dispatch(changeApplication(applicationIdFromRoute));
        }

        if (applicationIdFromRoute === "piggly-wiggly") {
            setBackgroundClass('piggly-wiggly');
            document.title = "Piggly Wiggly Registration";
        } else {
            setBackgroundClass('imperial');
            document.title = "ImperialDade Registration";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateFields(false);
    });

    function formatRegistrationData(formValues) {
        const formattedPhoneNumber = "+1" + formValues.phonenumber;
        return {
            action: "register",
            username: formValues.username,
            tempPassword: formValues.currentpassword,
            password: formValues.newpassword,
            attributes: {
                email: formValues.emailaddress,
                phone: formattedPhoneNumber
            }
        };
    }

    function onSubmit(e) {
        e.preventDefault();

        if (applicationIdFromRoute !== defaultApplicationId) {
            return false;
        }

        if (username && validUsername &&
            currentpassword && validCurrentPassword &&
            newpassword && validNewPassword &&
            emailaddress && validEmailAddress &&
            phonenumber && validPhoneNumber) {

            dispatch(onRegister(formatRegistrationData({
                username,
                currentpassword,
                newpassword,
                emailaddress,
                phonenumber
            })))
        } else {
            validateFields(true);
            console.log("[RegistrationForm]: form is invalid");
        }
    }

    function onChange(e) {
        switch (e.target.name) {
            case "username":
                setUsername(e.target.value);
                setChangedUsername(true);
                break;
            case "currentpassword":
                setCurrentPassword(e.target.value);
                setChangedCurrentPassword(true);
                break;
            case "newpassword":
                setNewPassword(e.target.value);
                setChangedNewPassword(true);
                break;
            case "confnewpassword":
                setConfNewPassword(e.target.value);
                setChangedConfNewPassword(true);
                break;
            case "emailaddress":
                setEmailAddress(e.target.value);
                setChangedEmailAddress(true);
                break;
            case "phonenumber":
                const phoneNumber = e.target.value.replace(/[^0-9]/g, '');
                if (phoneNumber.length <= 10) {
                    setPhoneNumber(phoneNumber);
                }
                setChangedPhoneNumber(true);
                break;
            default:
                console.log(`[RegistrationForm]: onChange event raised by unrecognized target: ${e.target.name}`);
        }
    }

    function isFormValid(validUsername, validCurrentPassword, validNewPassword, validConfNewPassword, validPhoneNumber, validEmailAddress) {
        return validUsername && validCurrentPassword && validNewPassword && validConfNewPassword && validPhoneNumber && validEmailAddress;
    }

    function validationMessage(validUsername, validCurrentPassword, validNewPassword, validPhoneNumber, validEmailAddress) {
        if (!validUsername) {
            return "Username is required";
        } else if (!validCurrentPassword) {
            return "Current password is required";
        } else if (!validNewPassword) {
            return "New password is required";
        } else if (!validConfNewPassword) {
            return "Password confirmation doesn't match"
        } else if (!validPhoneNumber) {
            return "10 digit phone number is required";
        } else if (!validEmailAddress) {
            return "ImperialDade email is required";
        }
        return "";
    }

    function formatErrorMessage(registrationError, formatClasses) {
        let errorMessage
        switch (registrationError.status) {
            case 401:
                errorMessage = "Incorrect username or password";
                break;
            case 500:
                errorMessage = "System error please contact customersupport@imperialdade.com";
                break;
            case 400:
            default:
                errorMessage = registrationError.message;
                break;
        }
        return (<div className={ formatClasses.errorMessage }><center><strong>{errorMessage}</strong></center></div>);
    }

    return (
        <form className="form" onSubmit={onSubmit}>
            { applicationIdFromRoute === "piggly-wiggly" ? <PigglywigglyHeader /> : <ImperialHeader />}
            <ImperialTextField name="username"
                labelText="Username"
                formControlProps={{
                    fullWidth: true
                }}
                value={username}
                onChange={onChange}
                error={!validUsername}
            />
            <ImperialPasswordField name="currentpassword"
                labelText="Current Password"
                formControlProps={{
                    fullWidth: true
                }}
                value={currentpassword}
                onChange={onChange}
                error={!validCurrentPassword}
            />
            <ImperialPasswordField name="newpassword"
                labelText="New Password"
                formControlProps={{
                    fullWidth: true
                }}
                value={newpassword}
                onChange={onChange}
                error={!validNewPassword}
            />
            <ImperialPasswordField name="confnewpassword"
                labelText="Confirm New Password"
                formControlProps={{
                    fullWidth: true
                }}
                value={confnewpassword}
                onChange={onChange}
                error={!validConfNewPassword}
            />
            <ImperialTextField name="emailaddress"
                labelText="Email Address"
                formControlProps={{
                    fullWidth: true
                }}
                value={emailaddress}
                onChange={onChange}
                error={!validEmailAddress}
            />
            <ImperialTextField name="phonenumber"
                labelText="Phone Number"
                formControlProps={{
                    fullWidth: true
                }}
                value={phonenumber}
                onChange={onChange}
                error={!validPhoneNumber}
            />
            {
                (isFormValid(validUsername, validCurrentPassword, validNewPassword, validPhoneNumber, validEmailAddress) ? "" :
                    <div className={ applicationIdFromRoute === "piggly-wiggly" ? pigglywigglyClasses.errorMessage : classes.errorMessage }>
                        <center>
                            <strong>{
                                validationMessage(validUsername, validCurrentPassword, validNewPassword, validPhoneNumber, validEmailAddress)
                            }</strong>
                        </center>
                    </div>
                )
            }
            {
                registrationError && formatErrorMessage(registrationError, applicationIdFromRoute === "piggly-wiggly" ? pigglywigglyClasses : classes)
            }
            <Button
                variant="contained"
                type="submit"
                className={ 
                    applicationIdFromRoute === "piggly-wiggly" ?
                    classNames({
                      [pigglywigglyClasses.primary]: true,
                    }) :
                    classNames({
                    [buttonClasses.round]: true,
                    [buttonClasses.button]: true, 
                    [buttonClasses.primary]: true,
                  })}
            >
                {isFormSubmitting ? <CircularProgress size={25} color="inherit" /> : "Submit"}
            </Button>
            <Typography variant="subtitle2" gutterBottom component="div">
                <hr />
                <b>Need assistance? </b>
                We are here to help! Email us at customersupport@imperialdade.com
            </Typography>
        </form>
    );
}

const mapStateToProps = state => ({
    isFormSubmitting: state.registration.loading,
    registrationError: state.registration.error,
});

export default connect(mapStateToProps, { onRegister, onRegisterClean })(RegistrationForm);