import axios from 'axios';
import { createSlice } from "@reduxjs/toolkit";
import { PASSWORD_RESET} from '../api-types';
import {  setMessage,  clearMessage } from '../slices/messageSlice';
import pages from "../page-links";
import history from '../history';

const initialState = {
  error: null,
  loading: false,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    //RESET_PASSWORD_BEGIN = "resetPassword/begin"
    resetPasswordBegin: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    //RESET_PASSWORD_COMPLETED = "resetPassword/completed";
    resetPasswordCompleted: (state, action) => {
      state.error = null;
      state.loading = false;
    },
    //RESET_PASSWORD_FAILED = "resetPassword/failed";
    resetPasswordFailed: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
  }
});

// export actions for each case reducer function
export const { resetPasswordBegin, resetPasswordCompleted, resetPasswordFailed } = resetPasswordSlice.actions;

// export reducers
export default resetPasswordSlice.reducer;


export const onResetPassword = formValues => async (dispatch, getState) => {
  const applicationId = getState().application.current.id;
  const environment = getState().application.environment;

  dispatch(resetPasswordBegin());
  try {
    const { username, password, resetCode } = formValues;
    const response = await axios.post(process.env.REACT_APP_AUTH_API, {
      action: PASSWORD_RESET,
      application: applicationId,
      code: resetCode,
      environment,
      password,
      username,
    });

    dispatch(resetPasswordCompleted(response.data));
    setEphemeralMessage("Password reset successfully", "success", dispatch);
    history.push(pages.loginPage(applicationId));

  } catch (err) {
    dispatch(resetPasswordFailed({ error: JSON.parse(JSON.stringify(err)) }));
    setEphemeralMessage("Password reset failed", "error", dispatch);
  }
};

export const setEphemeralMessage = (text, type, dispatch) => {
  dispatch(setMessage(text, type));
  window.setTimeout(() => {
    dispatch(clearMessage());
  }, 6000);
}
