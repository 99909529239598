import { createSlice } from "@reduxjs/toolkit";

/* cSpell:ignore webforms, punchout */
import get from "lodash/get";

//selectors
export const getApplication = state => state.application.current;
export const getApplicationId = state => state.application.current.id;
export const getApplicationName = state => state.application.current.name;
export const getEnvironment = state => state.application.environment;
export const getSelectable = state => state.application.selectable;

export const applications = {
  "content-management": {
    id: "content-management",
    name: "Content Management",
  },
  "cp-impersonation": {
    id: "cp-impersonation",
    name: "Customer Portal",
  },
  "customer-portal": {
    id: "customer-portal",
    name: "Customer Portal",
  },
  "customer-admin": {
    id: "customer-admin",
    name: "Customer Administration",
  },
  "data-service-admin": {
    id: "data-service-admin",
    name: "Data Service Admin",
  },
  "fedex-labels": {
    id: "fedex-labels",
    name: "FedEx Labels",
  },
  "image-comparison-admin": {
    id: "image-comparison-admin",
    name: "Image Comparison Administration",
  },
  "messaging-admin": {
    id: "messaging-admin",
    name: "Messaging Administration",
  },
  "quote-tool": {
    id: "quote-tool",
    name: "Quote Tool",
  },
  "sales-portal": {
    id: "sales-portal",
    name: "Sales Portal",
  },
  "login-admin": {
    id: "login-admin",
    name: "User Administration"
  },
  "punchout-admin": {
    id: "punchout-admin",
    name: "Punchout Administration"
  },
  "webforms": {
    id: "webforms",
    name: "Web Forms",
  },
  "elastic-document-logs": {
    id: "elastic-document-logs",
    name: "Elastic Document Logs",
  },
  "piggly-wiggly": {
    id: "piggly-wiggly",
    name: "Piggly Wiggly Customer Portal",
  },
  "keyword-redirect-admin": {
    id: "keyword-redirect-admin",
    name: "Keyword Redirect Management",
  },
  "payment-console": {
    id: "payment-console",
    name: "Payment Console"
  },
};

const DEFAULT_APPLICATION = "customer-portal";

export const getDefaultApplication = () => applications[DEFAULT_APPLICATION];
export const getDefaultEnvironment = () => "v1";

const initialState = {
  current: getDefaultApplication(),
  environment: process.env.PUBLIC_URL.substring(1),
  selectable: false,
  redirectOverride: "",
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    //APPLICATION_CHANGED = "application/set-current"
    changeApplication: (state, action) => {
      const app = get(applications, `${action.payload}`, getDefaultApplication());
      state.current = app;
    },
    //ENVIRONMENT_CHANGED = "application/environment"
    changeEnvironment: (state, action) => {
      //state.environment = action.payload;
    },
    //REDIRECT_OVERRIDE_CHANGED = "application/redirect-override"
    changeRedirectOverride: (state, action) => {
      state.redirectOverride = action.payload;
    },
    //TOGGLE_SELECTABLE = "application/toggle-selectable";
    enableSelectable: (state, action) => {
      state.selectable = action.payload;
    },
  },
})

// export actions for each case reducer function
export const { changeApplication, changeEnvironment, changeRedirectOverride, enableSelectable } = applicationSlice.actions;

// export reducers
export default applicationSlice.reducer;

export const getRedirectOverrideFromRoute = props => {
  const search = get(props, "history.location.search");
  if (!search) return null;

  const query = new URLSearchParams(search);
  return query.get("redirect_override");
};

export const getReturnUrlFromRoute = props => {
  const search = get(props, "history.location.search");
  if (!search) return "";

  const query = new URLSearchParams(search);
  return query.get("returnUrl");
};

export const getEnvironmentFromRoute = props => {
  return get(props, "match.params.environment", getDefaultEnvironment());
};

export const getApplicationFromRoute = props => {
  return get(props, "match.params.application", getDefaultApplication()["id"]);
};

export const getApplicationFromRouteNoDefault = props => {
  return get(props, "match.params.application");
};

export const getIsValidApplication = applicationInUrl => {
  return (typeof (get(applications, applicationInUrl)) !== "undefined");
}
