import React, { Component } from "react";
import logo from "../../../images/PigglywigglyLogo2.png";

class PigglywigglyHeader extends Component {
  render() {
    return (
      <div className="pigglywiggly-logo-container">
        <img src={logo} alt="Pigglywiggly Logo" />
      </div>
    );
  }
}

export default PigglywigglyHeader;
