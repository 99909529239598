import axios from 'axios';
import { get, set } from "lodash";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN, MFA_CODE_SUBMISSION } from '../api-types';

import pages from "../page-links";
import history from '../history';

axios.defaults.withCredentials = !process.env.REACT_APP_IS_LOCAL_DEV;

//selectors
export const getLoading = state => state.login.loading;
export const getUsername = state => state.login.username;
export const getPassword = state => state.login.password;
export const getMfaChannel = state => state.login.mfa_channel;
export const getMfaDestination = state => state.login.mfa_destination;
export const getRedirectUrl = state => state.login.redirect_url;
export const getAccessToken = state => state.login.access_token;
export const getIsMfaCodeValid = state => state.login.mfa_valid;
export const getMfaSession = state => state.login.mfa_session;
export const getApplicationName = state => state.application.current.name;
export const getReturnUrl = state => state.login.return_url;

export const setIf = (obj, path, val) => {
  if (val) set(obj, path, val);
  return obj;
}

export const onLogin = createAsyncThunk(
  "login/onLogin",
  async (credentials, { getState, dispatch }) => {

    const username = getState().login.username;
    const password = getState().login.password;

    const application = getState().application.current.id;
    const environment = getState().application.environment;
    const redirectOverride = getState().application.redirectOverride;

    try {
      const data = setIf({
        username,
        password,
        application,
        environment,
        action: LOGIN
      }, "redirectOverride", redirectOverride);


      const resp = await axios.post(process.env.REACT_APP_AUTH_API, data);
      console.log("onLogin data response: ", resp.data);

      if (resp.data) {
        if ("SMS_MFA" === get(resp, "data.ChallengeName", null)) {
          console.log("challenge?", get(resp, "data.ChallengeName", null));
          dispatch(mfaStart(resp.data));
          history.push(pages.mfaCodeEntryPage(environment, application) + "/" + application);
        } else {
          console.log("passwordOK");
          dispatch(passwordOk(resp.data));
        }
        return resp.data; //? payload to onLogin.fulfilled
      } else {
        dispatch(passwordFailed({ error: { message: "No data returned from API" } }))
      }

    } catch (err) {
      const errorResp = get(err, "response", 500);
      if (errorResp.status === 401) {
        dispatch(passwordFailed({ error: { message: errorResp.data.Error } }));
      } else {
        dispatch(passwordFailed({ error: { message: "Unknown exception" } }));
      }

    }
  }
);

export const onMfaCodeSubmission = createAsyncThunk(
  "login/onMfaCodeSubmission",
  async (mfaCode, { getState, dispatch }) => {

    const application = getState().application.current.id;
    const environment = getState().application.environment;
    const redirectOverride = getState().application.redirectOverride;

    const mfaSession = getState().login.mfa_session;
    const username = getState().login.username;

    try {
      const data = setIf({
        application,
        environment,
        username,
        code: mfaCode,
        session: mfaSession,
        action: MFA_CODE_SUBMISSION
      }, "redirectOverride", redirectOverride);

      const resp = await axios.post(process.env.REACT_APP_AUTH_API, data);
      dispatch(mfaSuccess(resp.data));
      return resp.data; //? payload to onMfaCodeSubmission.fulfilled

    } catch (err) {
      dispatch(mfaFailed({ error: JSON.parse(JSON.stringify(err)) }));
    }
  }
);

export const onResendRequested = createAsyncThunk(
  "login/onResendRequested",
  async (_, { getState, dispatch }) => {

    const application = getState().application.current.id;
    const environment = getState().application.environment;
    const username = getState().login.username;
    const password = getState().login.password;

    try {
      const resp = await axios.post(process.env.REACT_APP_AUTH_API, {
        application,
        environment,
        username,
        password,
        action: LOGIN
      });

      if ("SMS_MFA" === get(resp, "data.ChallengeName", null)) {
        dispatch(mfaResend(resp.data));
        return resp.data; //? payload to onResendRequested.fulfilled
      } else {
        dispatch(mfaFailed({ error: { message: "Unexpected or missing challenge name" } }));
      }
    } catch (err) {
      dispatch(mfaFailed({ error: JSON.parse(JSON.stringify(err)) }));
    }
  }
);

const initialState = {
  username: "",
  password: "",
  passwordOk: false,
  passwordError: "",
  loading: false,
  mfa_valid: true,
  redirect_url: "",
  return_url: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.passwordOk = false;
      state.passwordError = false;
    },
    mfaStart: (state, action) => {
      state.mfa_session = action.payload.Session;
      state.mfa_channel = action.payload.ChallengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM;
      state.mfa_destination = action.payload.ChallengeParameters.CODE_DELIVERY_DESTINATION;
    },
    mfaResend: (state, action) => {
      state.mfa_resend = true;
      state.mfa_session = action.payload.Session;
      state.mfa_channel = action.payload.ChallengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM;
      state.mfa_destination = action.payload.ChallengeParameters.CODE_DELIVERY_DESTINATION;
    },
    mfaSuccess: (state, action) => {
      state.access_token = action.payload.AccessToken;
      state.id_token = action.payload.IdToken;
      state.refresh_token = action.payload.RefreshToken;
      state.redirect_url = action.payload.RedirectUrl;
    },
    passwordOk: (state, action) => {
      state.access_token = action.payload.AccessToken;
      state.id_token = action.payload.IdToken;
      state.refresh_token = action.payload.RefreshToken;
      state.redirect_url = action.payload.RedirectUrl;
    },
    mfaFailed: (state, action) => {
      state.mfa_valid = false;
    },
    passwordFailed: (state, action) => {
      state.passwordOk = false;
      state.passwordError = action.payload.error.message;
    },
    changeReturnUrl: (state, action) => {
      state.return_url = action.payload;
    },
  },
  extraReducers: {
    [onLogin.pending]: (state, { meta }) => {
      state.username = meta.arg.username;
      state.password = meta.arg.password;
      state.passwordOk = false;
      state.passwordError = false;
      state.loading = true;
    },
    [onLogin.fulfilled]: (state, { meta, payload }) => {
      state.loading = false;
    },
    [onLogin.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
    },
    [onMfaCodeSubmission.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [onMfaCodeSubmission.fulfilled]: (state, { meta, payload }) => {
      state.loading = false;
    },
    [onMfaCodeSubmission.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
    },
    [onResendRequested.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [onResendRequested.fulfilled]: (state, { meta, payload }) => {
      state.loading = false;
    },
    [onResendRequested.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
    },
  },
});

// export actions for each case reducer function
export const { setCredentials, mfaStart, mfaResend, mfaSuccess, passwordOk, confirmationFailed, mfaFailed, passwordFailed, resetFailed, changeReturnUrl } = loginSlice.actions;

// export reducers
export default loginSlice.reducer;