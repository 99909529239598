import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  error: false,
  toastContent: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showSuccessToast: (state, { payload }) => {
      state.open = true;
      state.message = payload;
      state.error = false;
    },
    closeToast: (state) => {
      state.open = false;
      state.message = "";
      state.toastContent = "";
    },
    showErrorToast: (state, { payload }) => {
      state.open = true;
      state.error = true;
      state.message = payload;
    },
    setToastContent: (state, { payload }) => {
      state.toastContent = payload;
    },
  },
});

// actions
export const {
  closeToast,
  showErrorToast,
  showSuccessToast,
  setToastContent,
} = toastSlice.actions;

// selectors
export const getUserDetails = (state) => state.user.user;
export const userDetailFormErrors = (state) => state.user.errors;

// reducer
export default toastSlice.reducer;
