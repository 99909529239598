import React from 'react';
import { FormControl, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { applications, changeApplication, getApplicationId } from '../../../slices/applicationSlice';

import styles from "../../../assets/jss/imperial-input-style";
const useStyles = makeStyles(styles);

const ImperialApplicationSelect = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const applicationId = useSelector(getApplicationId);

  function onChange(e) {
    dispatch(changeApplication(e.target.value));
  }

  return (
    <FormControl className={classes.formControl} variant="standard">
      <TextField
        id="application"
        name="application"
        label="Application"
        variant="outlined"
        helperText="Select an application to login to"
        value={applicationId}
        onChange={onChange}
        select
        required
      >
        {Object.values(applications).map((app) => {
          return (
            <MenuItem key={app.id} value={app.id}>
              {app.name}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};


export default withRouter(ImperialApplicationSelect);