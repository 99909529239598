import { createSlice } from "@reduxjs/toolkit";

//selectors
export const getMessageText = state => state.message.text;
export const getMessageType = state => state.message.type;
export const hasMessage     = state => state.message.show;

const initialState = {
  type: "error",
  text: "",
  show: false,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    //SET_MESSAGE = "message/set"
    setMessage: (state, action) => {
      state.show = true;
      state.text = action.payload;
    },
    //CLEAR_MESSAGE = "message/clear"
    clearMessage: (state, action) => {
      state.text = "";
      state.show = false;
    },
  }
});

// export actions for each case reducer function
export const { setMessage, clearMessage } = messageSlice.actions;

// export reducers
export default messageSlice.reducer;



