const pigglyWigglyHelpContact = {
    email: "shopthepig@imperialdade.com",
    phone: "888-749-4126"
};

const imperialDadeHelpContact = {
    email: "ecommerce@imperialdade.com",
    phone: "866-336-3364"
};

const constants = {
    pigglyWigglyHelpContact,
    imperialDadeHelpContact
}

export default constants;



