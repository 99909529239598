import React, { Component } from 'react';
import logo from "../../../images/ImperialLogo.png";

class ImperialHeader extends Component {
    render() {
        return (
            <div className="imperial-logo-container">
                <img src={logo} alt="Imperial Dade Logo" />
            </div>
        );
    }
}

export default ImperialHeader;