const PigglywigglyMessageStyle = {
  errorMessage: {
    color: "#ff0000 !important",
  },
  labelRootError: {
    color: "#ff0000 !important",
  },
  primary: {
    color: "#ffffff",
    backgroundColor: "#d72832 !important",
    // boxShadow:
    //   "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
    // "&:hover,&:focus": {
    //   backgroundColor: `#d72832 !important`,
    //   boxShadow: `0 5px 10px -6px #ff0000 !important`
    // }
  },
};

export default PigglywigglyMessageStyle;
