import { useState, useEffect } from "react";
import styles from "../../assets/jss/imperial-typography-style";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import ImperialHeader from "../forms/partials/ImperialHeader";
import PigglywigglyHeader from "../forms/partials/PigglywigglyHeader";
import { getIsValidApplication } from "../../slices/applicationSlice";
import { useBackground } from "../custom/controls/BackgroundProvider"; 

const redirectApp = (locationPathname) => {
  const pathNames = locationPathname.split("/");
  const applicationId = pathNames.filter((pathname) => {
    return getIsValidApplication(pathname) === true;
  });
  if (applicationId) {
    window.location.href = "login/" + applicationId;
  } else {
    window.location.href = "https://imperialdade.com/";
  }
};

const PageNotFoundForm = ({ location }) => {    

  const [counter, setCounter] = useState(5);

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { setBackgroundClass } = useBackground();

  const locationPathname = location.pathname;

  setTimeout(() => { redirectApp(locationPathname) }, 5000);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));      
    }, 1000);
    return () => {      
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (locationPathname.includes("piggly-wiggly")) {
      setBackgroundClass('piggly-wiggly');
      document.title = "Piggly Wiggly Login";
    } else {
      setBackgroundClass('imperial');
      document.title = "ImperialDade Login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPathname]);

  return (
    <div className="page-not-found-form">
      { locationPathname.includes("piggly-wiggly") ? <PigglywigglyHeader /> : <ImperialHeader /> }      
      <center>
        <Typography variant="h6">Oops, this page does not exist</Typography>
        <Typography className={classes.errorMessage} variant="subtitle1">
          {location.pathname}
        </Typography>
        <Typography variant="subtitle1">
          you are being redirected in {counter} sec.
        </Typography>
      </center>
    </div>
  );
};

export default PageNotFoundForm;
