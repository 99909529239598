import axios from 'axios';
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { onLogin } from "../slices/loginSlice";

axios.defaults.withCredentials = !process.env.REACT_APP_IS_LOCAL_DEV;

const initialState = {
  error: null,
  loading: false,
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    //REGISTRATION_START = "registration/start";
    registrationStart: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    //REGISTRATION_OK = "registration/ok";
    registrationOk: (state, action) => {
      state.error = null;
      state.loading = false;
    },
    //REGISTRATION_ERR = "registration/failed"
    registrationFailed: (state, action) => {
      state.error = action.payload.error; 
      state.loading = false;
    },
    //REGISTRATION_CLEAN_STATE = "registration/clean_state";
    registrationCleanState: (state, action) => {
      state.error = null;
      state.loading = false;
    },
  }
});

// export actions for each case reducer function
export const { registrationStart, registrationOk, registrationFailed, registrationCleanState } = registrationSlice.actions;

// export reducers
export default registrationSlice.reducer;


export const onRegister = formValues => async (dispatch, getState) => {
  const application = getState().application.current.id;
  const environment = getState().application.environment;

  dispatch(registrationStart(formValues));
  try {

    const resp = await axios.post(process.env.REACT_APP_AUTH_API, {
      ...formValues,
      application,
      environment,
    });


    if (resp.data) {
      dispatch(registrationOk(resp.data));
      dispatch(onLogin({
        username: formValues.username,
        password: formValues.password
      }));
    } else {
      console.log('err unknown resp');
      dispatch(registrationFailed({ error: { status: resp.status, message: get(resp, "data.message", "Unknown exception") } }));
    }
  } catch (err) {
    console.log('err resp', err)
    dispatch(registrationFailed({ error: { status: get(err, "response.status", 500), message: err.message } }));
  }
};

export const onRegisterClean = () => async (dispatch, getState) => {
  dispatch(registrationCleanState());
}