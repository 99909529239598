import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  changeApplication,
  getApplicationFromRoute,
  getApplicationId,
  getIsValidApplication,
} from "../../slices/applicationSlice";
import { getUsername as getUsernameLogin } from '../../slices/loginSlice';
import { getMessageType, getMessageText } from '../../slices/messageSlice';
import { onForgotPassword } from '../../slices/forgotPasswordSlice';

import ImperialHeader from '../forms/partials/ImperialHeader';
import PigglywigglyHeader from '../forms/partials/PigglywigglyHeader';
import HelpMessagePartial from '../forms/partials/HelpMessage';
import ImperialTextField from '../custom/controls/ImperialTextField';

import { useBackground } from "../custom/controls/BackgroundProvider";  

import classNames from "classnames";
import Alert from '@mui/material/Alert';
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/imperial-typography-style";
import buttonStyles from "../../assets/jss/imperial-button-style";
import pigglywigglyStyle from "../../assets/jss/pigglywiggly-style";
import constants from '../../constants';

const useStyles = makeStyles(styles);

const ForgotPasswordForm = (props) => {
  const classes = useStyles();
  const buttonClasses = makeStyles(buttonStyles)();
  const pigglywigglyClasses = makeStyles(pigglywigglyStyle)();
  const dispatch = useDispatch();
  const { setBackgroundClass } = useBackground();

  const message = useSelector(getMessageText);
  const messageType = useSelector(getMessageType);

  const [error, setError] = useState("");
  const [username, setUsername] = useState(useSelector(getUsernameLogin));
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  const applicationIdFromRoute = getApplicationFromRoute(props);
  const applicationIdFromState = useSelector(getApplicationId);
  const [helpContact, setHelpContact] = useState("");

  useEffect(() => {
    // check if application Id in URL is valid
    if (getIsValidApplication(applicationIdFromRoute) === false) {
      setError("Application unknown");
      console.log("[ForgetPasswordForm]: Application unknown");
    }
    else {
      // if application Id is different from state, then update state
      if (applicationIdFromRoute !== applicationIdFromState)
        dispatch(changeApplication(applicationIdFromRoute));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (applicationIdFromRoute === "piggly-wiggly") {
      setBackgroundClass('piggly-wiggly');
      document.title = "Piggly Wiggly Login";
      setHelpContact(constants.pigglyWigglyHelpContact);
    } else {      
      setBackgroundClass('imperial');
      document.title = "ImperialDade Login";
      setHelpContact(constants.imperialDadeHelpContact);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChange(e) {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      default:
        console.log(`[ForgotPasswordForm]: onChange event raised by unrecognized target: ${e.target.name}`);
    }
  }

  function onSubmit(e) {
    e.preventDefault();

    if (getIsValidApplication(applicationIdFromRoute) === false) {
      setError("Application unknown");
      return false;
    }

    setIsUsernameValid(!!username);
    if (!username) {
      setError("Username is required");
      return;
    }

    if (isUsernameValid) {
      dispatch(onForgotPassword({
        username
      }));
    } else {
      console.log("[ForgotPasswordForm]: form is invalid, missing username");
    }
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      {applicationIdFromRoute === "piggly-wiggly" ? (
        <PigglywigglyHeader />
      ) : (
        <ImperialHeader />
      )}
      <Typography variant="subtitle2" gutterBottom component="div">
        Enter your username below, we'll send you a link you have to use to set
        a new password
      </Typography>
      {!!message && <Alert severity={messageType}>{message}</Alert>}
      <ImperialTextField
        name="username"
        labelText="Username"
        formControlProps={{
          fullWidth: true,
        }}
        value={username}
        onChange={onChange}
        error={!isUsernameValid}
      />
      {error && (
        <div
          className={
            applicationIdFromRoute === "piggly-wiggly"
              ? pigglywigglyClasses.errorMessage
              : classes.errorMessage
          }
        >
          <center>
            <strong>{error}</strong>
          </center>
        </div>
      )}
      <Button
        variant="contained"
        type="submit"
        className={
          applicationIdFromRoute === "piggly-wiggly"
            ? classNames({
                [pigglywigglyClasses.primary]: true,
              })
            : classNames({
                [buttonClasses.round]: true,
                [buttonClasses.button]: true,
                [buttonClasses.primary]: true,
              })
        }
      >
        Reset my password
      </Button>       

      <HelpMessagePartial helpContact={helpContact}/>
    </form>
  );
}

export default connect(null, { onForgotPassword })(ForgotPasswordForm);