import { configureStore } from "@reduxjs/toolkit";

import applicationReducer from "./slices/applicationSlice";
import forgotPasswordReducer from "./slices/forgotPasswordSlice";
import loginReducer from "./slices/loginSlice";
import messageReducer from "./slices/messageSlice";
import registrationReducer from "./slices/registrationSlice";
import resetPasswordReducer  from "./slices/resetPasswordSlice";
import toastReducer from "./slices/toast/toastSlice";

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    forgotPassword: forgotPasswordReducer,
    login: loginReducer,
    message: messageReducer,
    registration: registrationReducer,
    resetPassword:  resetPasswordReducer, 
    toast: toastReducer,
  },
})
