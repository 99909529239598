import { Alert, Box, styled } from "@mui/material";

const lightGreen = "#F9FEFA";
const darkGreen = "#006B32";
const darkRed = "#B9121B";
const lightRed = "#FBF3F3";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const StyledAlert = styled(Alert)(({ theme, error }) => ({
  width: "500px",
  backgroundColor: error ? lightRed : lightGreen,
  color: error ? darkRed : darkGreen,
  fontWeight: 700,
  border: "1px solid",
  borderColor: error ? darkRed : darkGreen,
  borderLeft: "8px solid",
}));

export const StyledError = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  placeItems: "center",
  backgroundColor: lightRed,
  padding: "8px",
  marginBottom: "8px",
  marginTop: "20px",
}));
