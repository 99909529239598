import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";

import LoginForm from "./forms/LoginForm";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import MfaCodeEntryForm from "./forms/MfaCodeEntryForm";
import RegistrationForm from "./forms/RegistrationForm";
import PageNotFoundForm from "./forms/PageNotFoundForm";

import { BackgroundProvider } from "./custom/controls/BackgroundProvider";

const App = () => {
  return (
    <BackgroundProvider>
      <Router history={history}>        
          <div>            
            <Switch>
              <Route exact path="/" component={LoginForm} />
              <Route exact path="/login" component={LoginForm} />
              <Route exact path="/login/:application" component={LoginForm} />
              <Route
                exact
                path="/mfa/:application"
                component={MfaCodeEntryForm}
              />
              <Route
                exact
                path="/forgot/:application"
                component={ForgotPasswordForm}
              />
              <Route
                exact
                path="/reset-password/:application"
                component={ResetPasswordForm}
              />
              <Route
                exact
                path="/registration/:application"
                component={RegistrationForm}
              />

              <Route path="*" component={PageNotFoundForm} />
            </Switch>
          </div>
        
      </Router>
      </BackgroundProvider>
  );
};

export default App;
