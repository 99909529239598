import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Snackbar } from "@mui/material";
import { closeToast, showSuccessToast } from "../../slices/toast/toastSlice";
import { StyledAlert } from "../custom/controls/Alerts";

import {
  changeApplication,
  getApplicationFromRoute,
  getEnvironmentFromRoute,
  getSelectable,
  enableSelectable,
  changeEnvironment,
  getRedirectOverrideFromRoute,
  changeRedirectOverride,
  getApplicationId,
  getReturnUrlFromRoute,
} from "../../slices/applicationSlice";

import { getReturnUrl, changeReturnUrl } from "../../slices/loginSlice";
import { getRedirectUrl, getAccessToken } from "../../slices/loginSlice";
import { getMessageType, getMessageText } from "../../slices/messageSlice";
import { onLogin } from "../../slices/loginSlice";

import ImperialHeader from "../forms/partials/ImperialHeader";
import PigglywigglyHeader from "../forms/partials/PigglywigglyHeader";
import HelpMessagePartial from "../forms/partials/HelpMessage";
import ImperialTextField from "../custom/controls/ImperialTextField";
import ImperialPasswordField from "../custom/controls/ImperialPasswordField";
import ImperialApplicationSelect from "../custom/controls/ImperialApplicationSelect";

import { Button, CircularProgress, Link, Typography } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import classNames from "classnames";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/imperial-typography-style";
import pigglywigglyStyle from "../../assets/jss/pigglywiggly-style";
import buttonStyles from "../../assets/jss/imperial-button-style";

import { useBackground } from "../custom/controls/BackgroundProvider";  
import constants from "../../constants";

const useStyles = makeStyles(styles);

const LoginForm = (props) => {
  const classes = useStyles();
  const buttonClasses = makeStyles(buttonStyles)();
  const pigglywigglyClasses = makeStyles(pigglywigglyStyle)();
  const dispatch = useDispatch();
  const { setBackgroundClass } = useBackground();

  const sessionExpired = useSelector((state) => state.toast);

  const redirectUrl = useSelector(getRedirectUrl);
  const accessToken = useSelector(getAccessToken);
  const message = useSelector(getMessageText);
  const messageType = useSelector(getMessageType);
  const applicationId = useSelector(getApplicationId);
  const isSelectable = useSelector(getSelectable);
  const returnUrl = useSelector(getReturnUrl);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validUsername, setValidUsername] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [showRegistrationLink, setShowRegistrationLink] = useState(false);
  const [showOtherEcommerceLink, setShowOtherEcommerceLink] = useState(false);

  const [helpContact, setHelpContact] = useState("");

  const { isFormSubmitting, loginFailedError } = props;

  const handleToastClose = () => {
    dispatch(closeToast());
  };

  useEffect(() => {
    const applicationIdOnStart = getApplicationFromRoute(props);
    dispatch(changeApplication(applicationIdOnStart));

    const environment = getEnvironmentFromRoute(props);
    dispatch(changeEnvironment(environment));

    const redirectOverride = getRedirectOverrideFromRoute(props);
    dispatch(changeRedirectOverride(redirectOverride));

    const returnUrlOnStart = getReturnUrlFromRoute(props);
    if (redirectUrl === "" && returnUrlOnStart !== "") dispatch(changeReturnUrl(returnUrlOnStart));

    if (applicationIdOnStart === "select") dispatch(enableSelectable(true));

    const urlParameters = new URLSearchParams(window.location.href);
    
    if (urlParameters.get("sessionExpired") === "true") {
        dispatch(showSuccessToast("Your session has expired due to inactivity.\nPlease login again."));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowRegistrationLink(false);
    setShowOtherEcommerceLink(false);
    
    if (applicationId === "sales-portal") {
      setShowRegistrationLink(true);
    } else if (applicationId === "customer-portal") {
      setShowRegistrationLink(false);
      setShowOtherEcommerceLink(true);
    } 
    
    if (applicationId === "piggly-wiggly") {
      setBackgroundClass('piggly-wiggly');
      document.title = "Piggly Wiggly Login";
      setHelpContact(constants.pigglyWigglyHelpContact);
    } else {      
      setBackgroundClass('imperial');
      document.title = "ImperialDade Login";
      setHelpContact(constants.imperialDadeHelpContact);
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  useEffect(() => {
    if (accessToken) {
      if (returnUrl && returnUrl !== "" && !returnUrl.includes("http")) {
         console.log(`[LoginForm] Returning to: https://${returnUrl} token=${accessToken}`);
        window.location.href = `https://${returnUrl}`;
      } else {
        console.log(`[LoginForm] Redirecting to: ${redirectUrl} token=${accessToken}`);
        window.location.href = `${redirectUrl}`;
      }
    }
  }, [accessToken, redirectUrl, returnUrl]);

  function onSubmit(e) {
    e.preventDefault();

    const isUsernameEmpty = validator.isEmpty(username, { ignore_whitespace: true });
    const isPassEmpty = validator.isEmpty(password, { ignore_whitespace: true });

    setValidUsername(!!username ? !isUsernameEmpty : false);
    setValidPassword(!!password ? !isPassEmpty : false);

    if (!isUsernameEmpty && !isPassEmpty) {
      //props or dispatch
      dispatch(
        onLogin({
          username,
          password,
        }),
      );
    } else {
      console.log("[LoginForm]: form is invalid");
    }
  }

  function onChange(e) {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        console.log(`[LoginForm]: onChange event raised by unrecognized target: ${e.target.name}`);
    }
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        { applicationId === "piggly-wiggly" ? <PigglywigglyHeader /> : <ImperialHeader />}      
        {isSelectable ? <ImperialApplicationSelect /> : ""}
        {!!message && <Alert severity={messageType}>{message}</Alert>}
        <ImperialTextField
          name="username"
          labelText="Username"
          formControlProps={{
            fullWidth: true,
          }}
          value={username}
          onChange={onChange}
          error={!validUsername}
        />
        <ImperialPasswordField
          name="password"
          labelText="Password"
          formControlProps={{
            fullWidth: true,
          }}
          value={password}
          onChange={onChange}
          error={!validPassword}
        />
        {validUsername && validPassword ? (
          ""
        ) : (
          <div className={ applicationId === "piggly-wiggly" ? pigglywigglyClasses.errorMessage : classes.errorMessage }>
            <center>
              <strong>Invalid Username/Password</strong>
            </center>
          </div>
        )}
        {loginFailedError && (
          <div className={ applicationId === "piggly-wiggly" ? pigglywigglyClasses.errorMessage : classes.errorMessage }>
            <center>
              <strong>{loginFailedError}</strong>
            </center>
          </div>
        )}
        <Button
          variant="contained"
          type="submit"
          className={ 
            applicationId === "piggly-wiggly" ?
            classNames({
              [pigglywigglyClasses.primary]: true,
            }) :
            classNames({
            [buttonClasses.round]: true,
            [buttonClasses.button]: true, 
            [buttonClasses.primary]: true,
          })}
        >
          {isFormSubmitting ? <CircularProgress size={25} color="inherit" /> : "Login"}
        </Button>
        <br />
        <RouterLink to={"/forgot/" + useSelector(getApplicationId)} variant="subtitle2">
          Forgot Password?
        </RouterLink>
        {showRegistrationLink && (
          <div>
            <hr />
            <Typography variant="subtitle2" gutterBottom component="div">
              <b>Not registered? </b>
              <RouterLink to={"/registration/sales-portal"}>Register Today</RouterLink>
            </Typography>
          </div>
        )}
        {showOtherEcommerceLink && (
          <div>
            <hr />
            <Typography variant="subtitle2" gutterBottom component="div">
              <b>Puerto Rico user </b>
              <Link href={process.env.REACT_APP_PUERTO_RICO_LINK}>Click Here</Link>
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="div">
              <b>Canada user </b>
              <Link href={process.env.REACT_APP_CANADA_LINK}>Click Here</Link>
            </Typography>
          </div>
        )}
        {showRegistrationLink ? (
          <Typography variant="subtitle2" gutterBottom component="div">
            <hr />
            <b>Need assistance? </b>
            We are here to help! Email us at customersupport@imperialdade.com
          </Typography>
        ) : (
          <HelpMessagePartial helpContact={helpContact}/>
        )}
      </form>
      <Snackbar
          open={sessionExpired.open}
          autoHideDuration={15000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <StyledAlert
          error={sessionExpired.error}
          severity={sessionExpired.error ? "error" : "success"}
          variant="filled"
          onClose={handleToastClose}
          sx={sessionExpired.toastContent ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}}
        >
          {sessionExpired.message}
          {sessionExpired.toastContent && (
            <Typography sx={{ lineHeight: "1rem", width: "400px" }}>
              {sessionExpired.toastContent}
            </Typography>
          )
          }
        </StyledAlert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFormSubmitting: state.login.loading,
  loginFailedError: state.login.passwordError,
});

export default connect(mapStateToProps, { onLogin })(LoginForm);
