import axios from 'axios';
import { createSlice } from "@reduxjs/toolkit";
import { FORGOT_PASSWORD } from '../api-types';
import {  setMessage,  clearMessage } from '../slices/messageSlice';
import pages from "../page-links";
import history from '../history';

axios.defaults.withCredentials = !process.env.REACT_APP_IS_LOCAL_DEV;

//selectors
export const getUsername = state => state.forgotPassword.username;

const initialState = {
  error: null,
  loading: false,
  username: "",
};

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    //FORGOT_PASSWORD_BEGIN = "forgotPassword/begin"
    forgotPasswordBegin: (state, action) => {
      state.username = action.payload.username;
      state.error = null;
      state.loading = true;
    },
    //FORGOT_PASSWORD_COMPLETED = "forgotPassword/completed";
    forgotPasswordCompleted: (state, action) => {
      state.error = null;
      state.loading = false;
    },
    //FORGOT_PASSWORD_FAILED = "forgotPassword/failed";
    forgotPasswordFailed: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
  }
}
)

// export actions for each case reducer function
export const { forgotPasswordBegin, forgotPasswordCompleted, forgotPasswordFailed } = forgotPasswordSlice.actions;

// export reducers
export default forgotPasswordSlice.reducer;


export const onForgotPassword = formValues => async (dispatch, getState) => {
  const application = getState().application.current.id;
  const environment = getState().application.environment;

  dispatch(forgotPasswordBegin(formValues));

  try {
    const resp = await axios.post(process.env.REACT_APP_AUTH_API, {
      action: FORGOT_PASSWORD,
      environment,
      application,
      ...formValues,
    });

    if (resp.data) {
      dispatch(forgotPasswordCompleted(resp.data));
      history.push("/" + pages.resetPasswordPage() + "/" + application);
    }
  } catch (err) {
    setEphemeralMessage(err.message, "error", dispatch);
    dispatch(forgotPasswordFailed({ error: JSON.parse(JSON.stringify(err)) }));
  }
};

export const setEphemeralMessage = (text, type, dispatch) => {
  dispatch(setMessage(text, type));
  window.setTimeout(() => {
    dispatch(clearMessage());
  }, 6000);
}
