import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class HelpMessage extends Component {
    render() {
        return (            
            <Typography variant="subtitle2" gutterBottom component="div">  
            <hr />              
                <b>Need assistance? </b> 
                We are here to help! Email us at {this.props.helpContact.email} or call us at {this.props.helpContact.phone} and one of our team members will contact you. Thank you for the opportunity to serve you.
            </Typography>            
        );
    }
}

export default HelpMessage;