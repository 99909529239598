import {
  dangerColor
} from "./material-kit-react.js";

const ImperialMessageStyle = {
  errorMessage: {
    color: dangerColor + " !important"
  }
}

export default ImperialMessageStyle;