import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";

import {
  getLoading,
  getMfaChannel,
  getMfaDestination,
  getApplicationName,
  getRedirectUrl,
  getAccessToken,
  getIsMfaCodeValid,
  getMfaSession,
  onMfaCodeSubmission,
  onResendRequested,
  getReturnUrl,
} from '../../slices/loginSlice';
import { getApplicationFromRoute } from "../../slices/applicationSlice";

import ImperialHeader from '../forms/partials/ImperialHeader';
import PigglywigglyHeader from '../forms/partials/PigglywigglyHeader';
import ImperialTextField from '../custom/controls/ImperialTextField';

import { useBackground } from "../custom/controls/BackgroundProvider";

import { Button, CircularProgress, Link } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/imperial-typography-style";
import pigglywigglyStyle from "../../assets/jss/pigglywiggly-style";
import buttonStyles from "../../assets/jss/imperial-button-style";
import classNames from "classnames";
const useStyles = makeStyles(styles);

const MfaCodeEntryForm = props => {
  const classes = useStyles();
  const buttonClasses = makeStyles(buttonStyles)();
  const pigglywigglyClasses = makeStyles(pigglywigglyStyle)();
  const { setBackgroundClass } = useBackground();

  const [mfaCode, setMfaCode] = useState("");

  const isFormSubmitting = useSelector(getLoading);
  const mfaChannel = useSelector(getMfaChannel);
  const mfaDestination = useSelector(getMfaDestination);
  const applicationName = useSelector(getApplicationName);
  const redirectUrl = useSelector(getRedirectUrl);
  const accessToken = useSelector(getAccessToken);
  const validMfaCode = useSelector(getIsMfaCodeValid);
  const validMfaSession = useSelector(getMfaSession);
  const returnUrl = useSelector(getReturnUrl);

  const dispatch = useDispatch();
  const applicationIdFromRoute = getApplicationFromRoute(props);

  useEffect(() => {
    if (applicationIdFromRoute === "piggly-wiggly") {
      setBackgroundClass('piggly-wiggly');
      document.title = "Piggly Wiggly Login";
    } else {
      setBackgroundClass('imperial');
      document.title = "ImperialDade Login";
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  useEffect(() => {
    if (accessToken) {
      if (returnUrl && returnUrl !== "") {
        console.log(`[MfaCodeEntryForm] Returning to: https://${returnUrl} token=${accessToken}`);
        window.location.href = `https://${returnUrl}`;
      }
      else {
        console.log(`[MfaCodeEntryForm] Redirecting to: ${redirectUrl} token=${accessToken}`);
        window.location.href = `${redirectUrl}`;
      }
    }
  }, [accessToken, redirectUrl, returnUrl]);

  const onSubmit = e => {
    e.preventDefault();
    dispatch(onMfaCodeSubmission(mfaCode));
  }

  const onResend = e => {
    e.preventDefault();
    dispatch(onResendRequested())
  }

  function onChange(e) {
    switch (e.target.name) {
      case "mfaCode":
        setMfaCode(e.target.value);
        break;
      default:
        console.log(`[LoginForm]: onChange event raised by unrecognized target: ${e.target.name}`);
    }
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      { applicationIdFromRoute === "piggly-wiggly" ? <PigglywigglyHeader /> : <ImperialHeader />}
      <div>
        To confirm your identity we've sent a single use security code to your
        {(mfaChannel !== "SMS") ? ` email: ${mfaDestination} ` : ` cell phone at: ${mfaDestination} `}
        please enter the code below and submit to login to {applicationName}
      </div>
      <ImperialTextField name="mfaCode"
        labelText="Security Code"
        formControlProps={{
          fullWidth: true
        }}
        value={mfaCode}
        onChange={onChange}
        error={!validMfaCode}
      />
      {(validMfaCode) ? "" :
        <div className={ applicationIdFromRoute === "piggly-wiggly" ? pigglywigglyClasses.errorMessage : classes.errorMessage }>
          <center><strong>Invalid Code</strong></center>
        </div>}
      {(validMfaSession) ? "" :
        <div className={ applicationIdFromRoute === "piggly-wiggly" ? pigglywigglyClasses.errorMessage : classes.errorMessage }>
          <center>
            <strong>Session expired</strong>
          </center>
          <br />
          <center>
            <RouterLink to={"/login/" + applicationIdFromRoute} variant="subtitle2">
              Return to LOGIN
            </RouterLink>
          </center>
        </div>}
      {validMfaSession && <Button
        variant="contained"
        type="submit"
        className={ 
          applicationIdFromRoute === "piggly-wiggly" ?
          classNames({
            [pigglywigglyClasses.primary]: true,
          }) :
          classNames({
          [buttonClasses.round]: true,
          [buttonClasses.button]: true, 
          [buttonClasses.primary]: true,
        })}
      >
        {isFormSubmitting ? <CircularProgress size={25} color="inherit" /> : "Submit"}
      </Button>}
      <br />
      {validMfaSession && <Link component="button" onClick={onResend} variant="subtitle2">Resend Code</Link>}
    </form >
  );
}

export default MfaCodeEntryForm;