import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  changeApplication,
  getApplicationFromRoute,
  getApplicationId,
  getIsValidApplication,
} from "../../slices/applicationSlice";
import { getUsername } from "../../slices/forgotPasswordSlice";
import { getMessageType, getMessageText } from '../../slices/messageSlice';
import { onResetPassword } from "../../slices/resetPasswordSlice";

import ImperialHeader from '../forms/partials/ImperialHeader';
import PigglywigglyHeader from '../forms/partials/PigglywigglyHeader';
import HelpMessagePartial from '../forms/partials/HelpMessage';
import ImperialPasswordField from '../custom/controls/ImperialPasswordField';
import ImperialTextField from '../custom/controls/ImperialTextField';

import { useBackground } from "../custom/controls/BackgroundProvider";

import Alert from '@mui/material/Alert';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/imperial-typography-style";
import pigglywigglyStyle from "../../assets/jss/pigglywiggly-style";
import buttonStyles from "../../assets/jss/imperial-button-style";

import classNames from "classnames";
import constants from '../../constants';

const useStyles = makeStyles(styles);

const ResetPasswordForm = props => {
  const classes = useStyles();
  const buttonClasses = makeStyles(buttonStyles)();
  const pigglywigglyClasses = makeStyles(pigglywigglyStyle)();
  const dispatch = useDispatch();
  const { setBackgroundClass } = useBackground();

  const message = useSelector(getMessageText);
  const messageType = useSelector(getMessageType);
  const usernameLogin = useSelector(getUsername);

  const [username, setUsername] = useState(usernameLogin);
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isValidResetCode, setIsValidResetCode] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [helpContact, setHelpContact] = useState("");

  const showUserName = (usernameLogin === "" ? true : false);

  const applicationIdFromRoute = getApplicationFromRoute(props);
  const applicationIdFromState = useSelector(getApplicationId);
  useEffect(() => {
    // check if application Id in URL is valid
    if (getIsValidApplication(applicationIdFromRoute) === false) {
      setErrorMessage("Application unknown");
      console.log("[ResetPasswordForm]: Application unknown");
    }
    else {
      // if application Id is different from state, then update state
      if (applicationIdFromRoute !== applicationIdFromState)
        dispatch(changeApplication(applicationIdFromRoute));
    }

    if (applicationIdFromRoute === "piggly-wiggly") {
      setBackgroundClass('piggly-wiggly');
      document.title = "Piggly Wiggly Login";
      setHelpContact(constants.pigglyWigglyHelpContact);
    } else {      
      setBackgroundClass('imperial');
      document.title = "ImperialDade Login";
      setHelpContact(constants.imperialDadeHelpContact);
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isPasswordComplex(pwd) {
    return /((?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,64})/.test(pwd);
  }

  function onChange(e) {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "resetCode":
        setResetCode(e.target.value);
        break;
      case "confirmedPassword":
        setConfirmedPassword(e.target.value);
        break;
      case "newPassword":
        setPassword(e.target.value);
        break;
      default:
        console.log(`[ResetPasswordForm]: onChange event raised by unrecognized target: ${e.target.name}`);
    }
  }

  function onSubmit(e) {
    e.preventDefault();

    if (getIsValidApplication(applicationIdFromRoute) === false) {
      setErrorMessage("Application unknown");
      return false;
    }

    const isResetCodeValid = /[0-9]{6}/.test(resetCode);
    const isPasswordValid = password
      && (password === confirmedPassword)
      && isPasswordComplex(password);

    setIsUsernameValid(!!username);
    setIsValidResetCode(isResetCodeValid);
    setIsValidPassword(isPasswordValid);
    setErrorMessage("");

    if (isUsernameValid && isResetCodeValid && isPasswordValid) {
      dispatch(onResetPassword({
        username,
        password,
        resetCode
      }));
    } else {
      if (!username) {
        setErrorMessage("Username is required");
      } else if (!password) {
        setErrorMessage("Password is required");
      } else if (!isResetCodeValid) {
        setErrorMessage("Reset code is required");
      } else if (!/[0-9]{6}/.test(resetCode)) {
        setErrorMessage("Reset code is invalid");
      } else if (password !== confirmedPassword) {
        setErrorMessage("Passwords do not match");
      } else {
        setErrorMessage("Password must contain at least one number, upper and lowercase letters and be 7 or more characters long");
      }
    }
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      { applicationIdFromRoute === "piggly-wiggly" ? <PigglywigglyHeader /> : <ImperialHeader />}
      <div>
        To confirm your identity we've sent a 6 digit security code to your
        ImperialDade email. Please enter the code and your new
        password in the fields below.
      </div>
      {!!message && (
        <Alert severity={messageType}>{message}</Alert>
      )}
      {showUserName && <ImperialTextField name="username"
        labelText="Username"
        formControlProps={{
          fullWidth: true
        }}
        value={username}
        onChange={onChange}
        error={!isUsernameValid}
      />}
      <ImperialTextField name="resetCode"
        labelText="Reset code"
        formControlProps={{
          fullWidth: true
        }}
        value={resetCode}
        onChange={onChange}
        error={!isValidResetCode}
      />
      <ImperialPasswordField name="newPassword"
        labelText="New password"
        formControlProps={{
          fullWidth: true
        }}
        value={password}
        onChange={onChange}
      />
      <ImperialPasswordField name="confirmedPassword"
        labelText="Confirm password"
        formControlProps={{
          fullWidth: true
        }}
        value={confirmedPassword}
        onChange={onChange}
        error={!isValidPassword}
      />
      {errorMessage &&
        (<div className={ applicationIdFromRoute === "piggly-wiggly" ?  pigglywigglyClasses.errorMessage : classes.errorMessage}>
          <center><strong>{errorMessage}</strong></center>
        </div>)}
      <Button
        variant="contained"
        type="submit"
        className={
          applicationIdFromRoute === "piggly-wiggly"
            ? classNames({
                [pigglywigglyClasses.primary]: true,
              })
            : classNames({
                [buttonClasses.round]: true,
                [buttonClasses.button]: true,
                [buttonClasses.primary]: true,
              })
        }
      >
        Reset Password
      </Button>
      <br />
      <HelpMessagePartial helpContact={helpContact}/>
    </form>
  );
}

export default connect(null, { onResetPassword })(ResetPasswordForm);
